

<template>
  <div class="carousel">
    <el-carousel :interval="10000" arrow="always" >
      <template v-for="(item, index) in fillData">
        <el-carousel-item :key="item + index" >
          <a :href="item[options.linkName]" class="carousel-item" target="_blank">
            <img :src="item[options.urlName]" :width="width" :height="height" alt />
          </a>
        </el-carousel-item>
      </template>
    </el-carousel>
  </div>
</template>
<script>

export default {
  name: 'Banner',
  data() {
    return {
    };
  },
  props:{
    fillData:{
      default(){
        return []
      }
    },
    width: { type: String },
    height: { type: String },
    options:{
      default(){
        return {
          linkName: 'linkUrl',
          urlName: 'picUrl'
        }
      }
    }
  },

};
</script>
<style lang="scss">
.carousel{
  width: 100%;
  img{
    width: 100%;
  }
  /deep/ .el-carousel__container{
    height: 500px;
  }
}
</style>
